@tailwind base;
@tailwind components;
@tailwind utilities;

.site-title {
    width: 80%;
    margin: 0 auto;
    line-height: 1;
    font-weight: 400;
    text-transform: uppercase;
    justify-self: center;
}

.active {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity));
    border-color: rgb(107 114 128 / var(--tw-bg-opacity));
    border-bottom-width: 2px;
}

.mobile.active {
    border: none;
    color: black;
}

